import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";

const Alert_before_clear = (props) => {
  const show = props.show;
  const onHide = props.onHide;
  const url = props.url;
  const dis_fun = props.dis_fun;
  const row = props.row_id;
  const user = props.user;
  const btnRef = useRef();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    btnRef.current.focus();
  });

  const toasts_error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      pauseOnHover: false,
    });
  };

  const handel_ok = async () => {
    setloading(true);
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
      body: JSON.stringify({
        ...row,
        status: "complete",
      }),
    });

    if (response.ok) {
      setloading(false);
      onHide();
      dis_fun(row);
    }
    if (!response.ok) {
      setloading(false);
      toasts_error("You Can't Clear this Order!");
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header className="p-3" closeButton>
        <Modal.Title>Clear Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <div className="alert alert-primary">Are you sure to Clear?</div>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <Button variant="default" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handel_ok} ref={btnRef}>
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Clear
        </Button>
      </Modal.Footer>
      <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark" />
    </Modal>
  );
};

export default Alert_before_clear;
