import React from "react";
import "./sale.css";
import Avatar from "@mui/material/Avatar";

function Salewidget(props) {
  return (
    <div
      className="col-2   text-center mb-3"
      onClick={(e) => props.funct(e, { value: props.item.id })}
    >
      <div
        className="card item p-2  "
        style={{ height: "16rem", cursor: "pointer" }}
      >
        <div
          style={{ minHeight: "160px" }}
          className=" d-flex justify-content-center align-items-center"
        >
          {props.item.picture ? (
            <img
              style={{ borderRadius: "10px", marginBottom: "20px" }}
              src={props.item.picture}
              width={120}
              height={150}
              alt="img"
            ></img>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <Avatar sx={{ width: 70, height: 70, bgcolor: "gray" }}>
                {props.item.name[0].toUpperCase()}
              </Avatar>
            </div>
          )}
        </div>

        <h6>{props.item.name}</h6>
        <h6>
          <strong>Rs. {props.item.sale_rate}</strong>
        </h6>
      </div>
    </div>
  );
}

export default Salewidget;
