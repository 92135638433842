import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import TextField from "@mui/material/TextField";

function Listwidget(props) {
  const handlekeydown = (e) => {
    if (e.key === "Enter") {
      props.functchange(false, props.item, { dataField: "show" });
    }
  };

  const handleclick = (e) => {
    props.closeallitem();
    props.functchange(true, props.item, { dataField: "show" });
  };
  return (
    <div className="border-bottom border-dark mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="m-0">{props.item.name}</h4>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="m-0 me-3">Rs. {props.item.sub_total}</h4>
          <IconButton
            className="border border-secondary circle p-0"
            onClick={(e) => props.funct(e, props.item)}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      {props.item.show ? (
        <div className="d-flex align-items-center justify-content-around mt-2 mb-2">
          <div>
            <TextField
              type="number"
              className="form-control"
              id="outlined-basic"
              label="Quantity"
              defaultValue={props.item.quantity}
              onChange={(e) =>
                props.functchange(e.target.value, props.item, {
                  dataField: "quantity",
                })
              }
              size="small"
              autoFocus
              onKeyDown={handlekeydown}
            />
          </div>

          <div>
            <TextField
              type="number"
              className="form-control"
              id="outlined-basic"
              label="Price"
              defaultValue={props.item.price}
              onChange={(e) =>
                props.functchange(e, props.item, { dataField: "price" })
              }
              onKeyDown={handlekeydown}
              size="small"
            />
          </div>
        </div>
      ) : (
        <div style={{ opacity: "0.4" }} onClick={handleclick}>
          {props.item.quantity} x {props.item.price}
        </div>
      )}
    </div>
  );
}

export default Listwidget;
