import React, { useEffect, useState } from "react";
import "../sale/sale.css";
import Button from "react-bootstrap/Button";
import { IconButton } from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import BootstrapTable from "react-bootstrap-table-next";
import Spinner from "react-bootstrap/Spinner";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Select from "react-select";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@mui/material/TextField";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faListOl } from "@fortawesome/free-solid-svg-icons";

import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Tooltip from "@material-ui/core/Tooltip";

import Saleform from "../purchase/purchaseform";

import { useTranslation } from "react-i18next";

function Quotation(props) {
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const { t } = useTranslation();
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const [stayfocus, setstayfocus] = useState(false);
  const table_data = props.state.Setproducthistory.product_history;
  const settable_data = props.Setproduct_history;
  const settings = props.state.Setcurrentinfo.settings;
  const dispatch = props.Settable_history;
  const [all_product_option, setall_product_option] = useState([]);
  const [all_product, setall_product] = useState([]);

  const [placeholder, setplaceholder] = useState(t("search_by_name"));
  const [name_color, setname_color] = useState("blue");
  const [code_color, setcode_color] = useState("lightgray");
  const [text, settext] = useState("");
  const [url, seturl] = useState("");

  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [date, setdate] = useState(curdate);
  const [counter, setcounter] = useState(1);
  const [notes, setnotes] = useState("");
  const [showmodel, setshowmodel] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [product, setproduct] = useState("");
  const [data, setdata] = useState("");

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "materialbook" });
    settable_data({ type: "Set_product_history", data: [] });
    const fetchProducts = async () => {
      var url = `${route}/api/raw-material/?account_head=${selected_branch.id}`;
      if (!settings?.user_base?.account_base) {
        if (current_user.profile.user_type === "user") {
          url = `${route}/api/raw-material/?user_id=${current_user.profile.parent_user}`;
        } else {
          url = `${route}/api/raw-material/?user_id=${current_user.id}`;
        }
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setall_product(json);
        const pro = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_product_option(pro);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [selected_branch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selected_branch) {
      setisloading(true);
      const data_details = table_data.map((item) => {
        delete item["prod_id"];
        delete item["name"];
        delete item["code"];
        delete item["product"];
        return item;
      });

      setisloading(true);

      const response = await fetch(`${route}/api/material-book/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify({
          user: current_user.id,
          account_head: selected_branch.id,
          remarks: notes,
          date: date,
          details: data_details,
        }),
      });
      const json = await response.json();

      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
      }

      if (response.ok) {
        setisloading(false);
        success_toast();
        settable_data({ type: "Set_product_history", data: [] });
        setdate("");
        setnotes("");
      }
    }
  };

  const handleNewSale = (e) => {
    e.preventDefault();
    settable_data({ type: "Set_product_history", data: [] });
    setdate("");
    setnotes("");
  };

  const handlePrint = async () => {
    if (selected_branch) {
      const data_details = table_data.map((item) => {
        delete item["prod_id"];
        delete item["name"];
        delete item["code"];
        delete item["product"];
        return item;
      });

      const response = await fetch(`${route}/api/material-book/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify({
          user: current_user.id,
          account_head: selected_branch.id,

          remarks: notes,
          date: date,

          details: data_details,
        }),
      });
      const json = await response.json();

      if (!response.ok) {
        went_wrong_toast();
      }

      if (response.ok) {
        localStorage.setItem("data", JSON.stringify(json));
        settable_data({ type: "Set_product_history", data: [] });
        setnotes("");
        setdate("");

        if (selected_branch.invoice_type === "Version 01") {
          window.open("/invoice/quotation", "_blank");
        } else if (selected_branch.invoice_type === "Version 02") {
          window.open("/invoice_2/quotation", "_blank");
        } else if (selected_branch.invoice_type === "Version 03") {
          window.open("/invoice_3/quotation", "_blank");
        }
      }
    }
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="action text-center">
        <IconButton
          onClick={() => {
            settable_data({ type: "Delete_product_history", data: row });
          }}
        >
          <DeleteRoundedIcon color="error" fontSize="medium" />
        </IconButton>
      </div>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const quantity_formatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <input
        type="number"
        defaultValue={cell}
        autoFocus={formatExtraData.dataField === "quantity" || stayfocus}
        className="form-control border-0"
        onChange={(e) => {
          var newValue = e.target.value;

          if (!newValue) {
            newValue = 0;
          }

          handlecellchange(cell, newValue, row, formatExtraData);
          setstayfocus(true);
        }}
      ></input>
    );
  };

  const name_column_formater = (cell, row) => {
    return <div style={{ width: "30vw" }}>{cell}</div>;
  };

  const columns = [
    {
      dataField: "row_number",
      text: "#",
      headerFormatter: headerstyle,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      editable: false,
    },
    {
      dataField: "code",
      text: t("code"),
      headerFormatter: headerstyle,
      editable: false,
    },
    {
      dataField: "name",
      text: t("name"),
      sort: true,
      headerFormatter: headerstyle,
      formatter: name_column_formater,
      editable: false,
    },
    {
      dataField: "quantity",
      text: t("qty"),
      formatter: quantity_formatter,
      headerFormatter: headerstyle,
      formatExtraData: { dataField: "quantity" },
    },

    {
      dataField: "Edit",
      text: t("action"),
      formatter: linkFollow,
      headerFormatter: headerstyle,
      editable: false,
    },
  ];

  const handlesearch_by_code = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: `${item.code} | ${item.name} `,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_code"));

    setname_color("lightgray");
    setcode_color("blue");
  };

  const handlesearch_by_name = () => {
    const pro = all_product.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setall_product_option(pro);
    setplaceholder(t("search_by_name"));

    setname_color("blue");
    setcode_color("lightgray");
  };

  const handleproduct_selection = (selected_option) => {
    const item_present = table_data.filter((item) => {
      return item.material === selected_option.value;
    });

    if (item_present.length === 0) {
      var filter_data = all_product.filter((item) => {
        if (item.id === selected_option.value) {
          return item;
        }
      });

      filter_data = filter_data.map((item) => {
        return {
          material: item.id,
          product: item.id,
          prod_id: counter,
          name: item.name,
          code: item.code,
          quantity: 1,
        };
      });
      settable_data({ type: "Create_product_history", data: filter_data[0] });
      setcounter(counter + 1);
    } else {
      const item = item_present[0];
      item.quantity = item.quantity + 1;

      settable_data({ type: "Update_product_history", data: item });
    }
  };

  const handlecellchange = (oldValue, newValue, row, column) => {
    if (column.dataField === "quantity") {
      var new_data = table_data.map((item) => {
        return item.material !== row.material
          ? item
          : {
              material: item.material,
              product: item.product,
              prod_id: item.prod_id,
              name: item.name,
              code: item.code,
              quantity: newValue,
            };
      });
      settable_data({ type: "Set_product_history", data: new_data });
    }
  };

  return (
    <div className="p-3">
      <ToolkitProvider
        keyField="prod_id"
        data={table_data}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div className="card">
            <div className="card-header  d-flex justify-content-end">
              <Button
                className="me-2"
                type="button"
                variant="outline-dark"
                onClick={handleNewSale}
              >
                <AddIcon /> {t("new")}
              </Button>

              <Button
                onClick={handleSubmit}
                variant="outline-primary"
                disabled={!table_data.length > 0}
              >
                {isloading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <SaveIcon /> {t("save")}
              </Button>

              <Button
                className="ms-2"
                variant="outline-success"
                onClick={handlePrint}
                disabled={!table_data.length > 0}
              >
                <PrintRoundedIcon /> {t("print")}
              </Button>
            </div>

            <div className="card-body  ">
              <div className="row">
                <div>
                  <div className="row  d-sm-flex justify-content-center align-items-start mt-1">
                    <div className="col-6 col-md-2 mb-2">
                      <TextField
                        type="date"
                        className="form-control   mb-3"
                        id="outlined-basic"
                        label="Date"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={date}
                        value={date}
                        onChange={(e) => {
                          setdate(e.target.value);
                        }}
                        size="small"
                      />
                    </div>

                    <div className="col-md-1 text-center">
                      <Tooltip title="Search Product by Code">
                        <IconButton onClick={handlesearch_by_code}>
                          <FontAwesomeIcon
                            color={code_color}
                            icon={faListOl}
                            size="lg"
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Search Product by Name">
                        <IconButton onClick={handlesearch_by_name}>
                          <FontAwesomeIcon
                            color={name_color}
                            icon={faTag}
                            size="lg"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="col-md-3">
                      <InputGroup>
                        <Select
                          className="form-control selector"
                          placeholder={placeholder}
                          options={all_product_option}
                          value={product}
                          onChange={handleproduct_selection}
                        ></Select>

                        <IconButton
                          className="p-0 ps-1 pe-1"
                          style={{
                            backgroundColor: "#0d6efd",
                            borderRadius: "0",
                          }}
                          onClick={() => {
                            settext(t("side_bar_product"));
                            seturl(
                              `${route}/api/products/?branch_id=${selected_branch.id}`
                            );
                            setshowmodel(!showmodel);
                            setdata(all_product);
                          }}
                        >
                          <VisibilityIcon
                            style={{
                              color: "white",
                              height: "fit-content",
                            }}
                            fontSize="medium"
                          />
                        </IconButton>
                      </InputGroup>
                    </div>

                    <div className="col-md-3">
                      <TextField
                        multiline
                        maxRows={4}
                        className="form-control   mb-3"
                        id="outlined-basic"
                        label={"Notes"}
                        value={notes}
                        onChange={(e) => {
                          setnotes(e.target.value);
                        }}
                        size="small"
                      />
                    </div>
                  </div>

                  <div className="row mt-2"></div>
                </div>
              </div>

              <hr />
              <div style={{ height: "60vh", overflow: "auto" }}>
                <BootstrapTable
                  {...props.baseProps}
                  bordered={false}
                  bootstrap4
                  condensed
                  rowClasses="custom_row_class"
                />
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>

      {/* {showmodel && (
        <Saleform
          show={showmodel}
          onHide={() => setshowmodel(false)}
          user={user}
          route1={url}
          callback={settable_data}
          text={text}
          counter={counter}
          setcounter={setcounter}
          
          table_data={table_data}
          tax_perc={tax_perc}
          selected_branch={selected_branch}
          route={route}
          data_={data}
        /> 
      )}*/}
    </div>
  );
}

export default Quotation;
