import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Spinner from "react-bootstrap/Spinner";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Alert_before_clear from "../alerts/alert_before_clear";
import Select from "../alerts/select";
import { useTranslation } from "react-i18next";

function Orders(props) {
  const user = props.state.setuser.user;
  const { t } = useTranslation();
  const route = props.state.setuser.route;
  const invoice_type = props.state.Setcurrentinfo.invoice_type;

  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const current_user = props.state.Setcurrentinfo.current_user;
  const history = props.state.Settablehistory.table_history;
  const dispatch = props.Settable_history;
  const settable_data = props.Setsavedata;
  const { SearchBar } = Search;
  const [delete_user, setdelete_user] = useState(false);
  const [url_to_delete, seturl_to_delete] = useState("");
  const [row_id, setrow_id] = useState("");

  const [isloading, setisloading] = useState(false);
  const [callagain, setcallagain] = useState(false);

  const [allordertypes, setallordertypes] = useState([
    { value: "all", label: "All" },
    { value: "Dine In", label: "Dine In" },
    { value: "Delivery", label: "Delivery" },
  ]);
  const [ordertype, setordertype] = useState({ value: "all", label: "All" });

  useEffect(() => {
    setisloading(true);
    dispatch({ type: "Set_table_history", data: [] });
    const fetchProducts = async () => {
      var url = `${route}/api/sales/?account_head=${selected_branch.id}&user_id=${current_user.id}&user_type=${current_user?.profile?.user_type}&status=pending`;
      if (ordertype.value !== "all") {
        url = `${url}&order_type=${ordertype.value}`;
      }
      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        dispatch({ type: "Set_table_history", data: json });
      }
      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
      }
    };

    if (user && current_user) {
      fetchProducts();
    }
  }, [selected_branch, ordertype, callagain, current_user]);

  const handleconfirm = (row) => {
    setcallagain(!callagain);
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <span className="action d-flex">
        <Button
          type="button"
          variant="success"
          onClick={() => {
            setrow_id(row);
            seturl_to_delete(`${route}/api/sales/${row.id}/`);
            setdelete_user(true);
          }}
        >
          Clear
        </Button>
      </span>
    );
  };

  const headerstyle = (column, colIndex, { sortElement }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ minHeight: "2.5rem" }}
      >
        {column.text}
        {sortElement}
      </div>
    );
  };

  const fix_formatter = (cell, row) => {
    return <div>{parseFloat(cell).toFixed(2)}</div>;
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true,
      headerFormatter: headerstyle,
      csvExport: false,
      footer: "",
    },
    {
      dataField: "date",
      text: t("date"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "invoice",
      text: t("invoice"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "customer_info.name",
      text: t("side_bar_customers"),
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "sale_person_name",
      text: "Sale Person",
      sort: true,
      headerFormatter: headerstyle,
    },
    {
      dataField: "table",
      text: "Table No",
      sort: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "total",
      text: "Total",
      sort: true,
      headerFormatter: headerstyle,
      formatter: fix_formatter,
    },
    {
      dataField: "order_type",
      text: "Order Type",
      sort: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "status",
      text: " Status",
      sort: true,
      headerFormatter: headerstyle,
    },

    {
      dataField: "Edit",
      text: t("action"),
      formatter: linkFollow,
      formatExtraData: invoice_type,
      headerFormatter: headerstyle,
      csvExport: false,
    },
  ];

  return (
    <div className="p-3">
      <h1 className="mb-3" style={{ fontSize: "1.8rem", fontWeight: "normal" }}>
        Orders
      </h1>
      <ToolkitProvider
        keyField="id"
        data={history}
        columns={columns}
        search
        exportCSV
      >
        {(props) => (
          <div className="card p-3">
            <div className="d-sm-flex justify-content-between align-items-center mt-3">
              <div className=" col-sm-2 ms-3  me-3  selector mb-2">
                <Select
                  options={allordertypes}
                  placeholder={"Order Type"}
                  value={ordertype}
                  funct={setordertype}
                ></Select>
              </div>
              <SearchBar {...props.searchProps} />
            </div>

            {isloading && (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            <hr />
            <div style={{ minHeight: "70vh", zoom: "0.9" }}>
              <BootstrapTable
                {...props.baseProps}
                bordered={false}
                bootstrap4
                condensed
                striped
                wrapperClasses="table-responsive"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
      {delete_user && (
        <Alert_before_clear
          show={delete_user}
          onHide={() => setdelete_user(false)}
          url={url_to_delete}
          dis_fun={handleconfirm}
          row_id={row_id}
          user={user}
        />
      )}
    </div>
  );
}

export default Orders;
