import React from "react";
import Modal from "react-bootstrap/Modal";
import "./sale.css";

import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import TextField from "@mui/material/TextField";

function Form({
  show,
  onHide,
  notes,
  setnotes,
  discount,
  setdiscount,
  discount_type,
  setdiscount_type,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-3 ">
          <IconButton
            onClick={() => {
              setdiscount_type("percentage");
            }}
            className="border border-secondary border-rounded me-3"
          >
            <FontAwesomeIcon
              icon={faPercent}
              color={discount_type === "percentage" ? "blue" : "secondary"}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              setdiscount_type("cash");
            }}
            className="border border-secondary border-rounded"
          >
            <AttachMoneyIcon
              style={{ color: discount_type === "cash" && "blue" }}
            />
          </IconButton>
        </div>
        <div className="mb-3">
          <TextField
            type="number"
            className="form-control"
            id="outlined-basic"
            label="Discount"
            value={discount}
            onChange={(e) => {
              setdiscount(e.target.value);
            }}
            size="small"
            autoFocus
          />
        </div>

        <hr className="mb-1"></hr>
        <div className=" d-flex flex-row-reverse mt-2 me-2">
          <Button type="submit" variant="outline-primary" onClick={onHide}>
            Done
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Form;
