import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Customer from "../../Container/customerContainer";
import Supplier from "../../Container/supplierContainer";
import CustomerType from "../../Container/customertypeContainer";
import Area from "../../Container/areaContainer";
import Saleperson from "../../Container/sale_personContainer";
import { ToastContainer } from "react-toastify";

function Parties_page({ current_user }) {
  const tabs = ["customers", "CustomerType", "area", "saleperson", "vendors"];
  var current_tab = localStorage.getItem("activeTab");
  if (!tabs.includes(current_tab)) {
    current_tab = "customers";
  }

  const [activeTab, setActiveTab] = useState(current_tab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Tabs
        defaultActiveKey={activeTab}
        transition={true}
        onSelect={handleTabSelect}
        id="noanim-tab-example"
        className="mb-3"
      >
        {current_user?.permissions?.includes("view_customer") && (
          <Tab eventKey="customers" title="Customers">
            {activeTab === "customers" && <Customer />}
          </Tab>
        )}

        {current_user?.permissions?.includes("view_supplier") && (
          <Tab eventKey="CustomerType" title="Customer Type">
            {activeTab === "CustomerType" && <CustomerType />}
          </Tab>
        )}

        {current_user?.permissions?.includes("view_supplier") && (
          <Tab eventKey="area" title="Area">
            {activeTab === "area" && <Area />}
          </Tab>
        )}
        {current_user?.permissions?.includes("view_supplier") && (
          <Tab eventKey="saleperson" title="Sale Person">
            {activeTab === "saleperson" && <Saleperson />}
          </Tab>
        )}
        {current_user?.permissions?.includes("view_supplier") && (
          <Tab eventKey="vendors" title="Vendors">
            {activeTab === "vendors" && <Supplier />}
          </Tab>
        )}
      </Tabs>
      <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Parties_page;
