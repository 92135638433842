import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useReactToPrint } from "react-to-print";
import "./80mminvoice.css";

function Invoice80mm(props) {
  const { name } = useParams();
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("selected_branch"))
  );
  const response = JSON.parse(localStorage.getItem("data"));
  const [data, setdata] = useState(response);
  const [customer, setcustomer] = useState(response.customer_info);
  const [details, setdetails] = useState(response.details);

  const headerstyle = (column, colIndex, { sortElement }) => {
    return <div className="d-flex align-items-center">{column.text}</div>;
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (row, cell, rowIndex) => {
        return rowIndex + 1;
      },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_name",
      text: "Detail",
      headerFormatter: headerstyle,
    },
    { dataField: "quantity", text: "Qty", headerFormatter: headerstyle },
    { dataField: "price", text: "Price", headerFormatter: headerstyle },
    {
      dataField: "total",
      text: "Total",
      headerFormatter: headerstyle,
    },
  ];

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass1",
    // pageStyle: "@page { width:80mm;height:auto }",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  return (
    <div
      className="m-2 pt-5"
      style={{
        width: "80mm",
        height: "auto",
        border: "1px solid black",
      }}
      ref={componentRef}
    >
      {data && (
        <div className="container_1 p-2">
          <h5 className="text-center m-0">
            <strong>{company.name}</strong>
          </h5>

          <div className="text-center">
            <p className="m-0" style={{ fontWeight: "normal" }}>
              <p className="m-0" style={{ fontWeight: "normal" }}>
                {new Date().toISOString().substring(0, 10)}{" "}
                {new Date().toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </p>
            </p>
          </div>

          <p className="text-center  ms-1 me-1">Order No : {data.invoice}</p>

          {(name === "sales" || name === "sales_return") && (
            <div className="d-flex justify-content-between mt-1  ">
              <div className=" text-left">
                <p className="m-0">Customer name : {customer.name}</p>
                <p className=" m-0">Customer Cell : {customer.contact}</p>
              </div>
            </div>
          )}

          {(name === "purchases" || name === "purchases_return") && (
            <div
              className="d-flex justify-content-between mt-1  "
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              <div className=" text-left">
                <p className="m-0">Party : {data.supplier_name}</p>
              </div>
            </div>
          )}

          <div className="mt-2 ">
            <BootstrapTable
              keyField="id"
              data={details}
              columns={columns}
              bordered={true}
              bootstrap4
              condensed
              classes="table_class_80mm"
            />
          </div>
          <div className="d-flex justify-content-end">
            <div className="col-sm-8  " style={{ border: "1px solid black" }}>
              <div className=" d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">Total</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.sub_total}
                </p>
              </div>

              <div className="  d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">Disc</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.discount_amount}
                </p>
              </div>

              <div className=" d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">After Disc</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.sub_total - data.discount_amount}
                </p>
              </div>

              <div className=" d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">Services Charges</p>
                <p className=" p-2 m-0 col-sm-4 border-start border-dark">
                  {data.tax_amount}
                </p>
              </div>

              <div className=" d-flex  justify-content-between  ">
                <p className="p-2 m-0 col-sm-8  ">Total</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.total}
                </p>
              </div>
            </div>
          </div>
          <p className=" text-center m-0 mt-3">Table No : {data.table}</p>
          <p className=" text-center m-0">Order Type :{data.order_type}</p>
          <h5 className=" text-center mt-3">Powered By eAvenir.com</h5>
          <h5 className=" text-center">03063516139</h5>
        </div>
      )}
    </div>
  );
}

export default Invoice80mm;
