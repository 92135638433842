import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useReactToPrint } from "react-to-print";
import "./80mminvoice.css";
import QRCode from "react-qr-code";

function Invoice80mmSaudia(props) {
  const { name } = useParams();
  const [company, setcompany] = useState(
    JSON.parse(localStorage.getItem("selected_branch"))
  );
  const response = JSON.parse(localStorage.getItem("data"));
  const [data, setdata] = useState(response);
  const [customer, setcustomer] = useState(response.customer_info);
  const [details, setdetails] = useState(response.details);

  const headerstyle = (column, colIndex) => {
    const englishHeader = column.text.english;
    const arabicHeader = column.text.arabic;

    return (
      <div>
        <div>{englishHeader}</div>
        <div>{arabicHeader}</div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: { english: "#", arabic: "" },
      formatter: (row, cell, rowIndex) => {
        return rowIndex + 1;
      },
      headerFormatter: headerstyle,
    },
    {
      dataField: "product_name",
      text: { english: "Details", arabic: "منتجات" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "quantity",
      text: { english: "Qty", arabic: "الكمية" },
      headerFormatter: headerstyle,
    },
    {
      dataField: "price",
      text: { english: "Price", arabic: "سعر الوحدة" },

      headerFormatter: headerstyle,
    },
    {
      dataField: "total",
      text: { english: "Total", arabic: "الاجمالي" },
      headerFormatter: headerstyle,
    },
  ];

  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "printclass1",
    // pageStyle: "@page { width:80mm;height:auto }",
    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (data) {
      handleprint();
    }
  }, [data]);

  return (
    <div
      className="m-2 pt-5"
      style={{
        width: "80mm",
        height: "auto",
        border: "1px solid black",
      }}
      ref={componentRef}
    >
      {data && (
        <div className="container_1 p-2">
          <h5 className="text-center m-0">
            <strong>فاتورة ضريبية مبسطة </strong>
          </h5>

          <h5 className="text-center m-0">
            <strong>{company.name}</strong>
          </h5>

          <h6 className="text-center m-0">
            <strong>{company.address}</strong>
          </h6>
          <h6 className="text-center m-0">
            <strong>{company.ntn}</strong>
          </h6>
          <h6 className="text-center m-0">
            <strong>{company.strn}</strong>
          </h6>

          <p className="text-center m-0">{data.invoice} : رقم الفاتورة</p>
          {data.payment_type && (
            <p className="text-center m-0">{data.payment_type} : نوع الدفع</p>
          )}

          <div className="text-center">
            <p className="m-0" style={{ fontWeight: "normal" }}>
              {new Date().toISOString().substring(0, 10)}{" "}
              {new Date().toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </p>
          </div>

          {(name === "sales" || name === "sales_return") && (
            <div className=" mt-1  ">
              <div className=" text-center">
                <p className="m-0">Customer name : {customer.name}</p>
                {customer.contact && (
                  <p className=" m-0">Customer Cell : {customer.contact}</p>
                )}
                {customer.ntn && (
                  <p className=" m-0">Customer VAT No : {customer.ntn}</p>
                )}
                {customer.address && (
                  <p className=" m-0">Customer Address : {customer.address}</p>
                )}
              </div>
            </div>
          )}

          {(name === "purchases" || name === "purchases_return") && (
            <div
              className="d-flex justify-content-between mt-1  "
              style={{ border: "1px solid black", borderRadius: "5px" }}
            >
              <div className=" text-left">
                <p className="m-0">Party : {data.supplier_name}</p>
              </div>
            </div>
          )}

          <div className="mt-2 ">
            <BootstrapTable
              keyField="id"
              data={details}
              columns={columns}
              bordered={true}
              bootstrap4
              condensed
              classes="table_class_80mm"
            />
          </div>
          <div className="d-flex justify-content-end">
            <div className="col-sm-8  " style={{ border: "1px solid black" }}>
              <div className=" d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">الاجمالي</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.sub_total}
                </p>
              </div>

              <div className="  d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">خصم</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.discount_amount}
                </p>
              </div>

              <div className=" d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">المجموع بعدخصم</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.sub_total - data.discount_amount}
                </p>
              </div>

              <div className=" d-flex  justify-content-between border-bottom border-dark ">
                <p className="p-2 m-0 col-sm-8  ">
                  {" "}
                  {company.tax_percentage} ضريبة %
                </p>
                <p className=" p-2 m-0 col-sm-4 border-start border-dark">
                  {data.tax_amount}
                </p>
              </div>

              <div className=" d-flex  justify-content-between  ">
                <p className="p-2 m-0 col-sm-8  "> الاجمالي المبلغ</p>
                <p className="p-2 m-0 col-sm-4 border-start border-dark">
                  {data.total}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-3 text-center qr-code">
            <QRCode
              value={data.qr_code}
              bgColor={"#FFFFFF"}
              fgColor={"#000000"}
              size={100}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Invoice80mmSaudia;
