import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer } from "react-toastify";
import Purchase from "../../Container/purchaseContainer";
import Purchase_history from "../../Container/purchasehistpryContainer";
import Purchase_Edit from "../../Container/purchaseeditContainer";

import Purchase_return from "../../Container/purchase_returnContainer";
import Purchase_return_history from "../../Container/purchase_returnhistoryContainer";
import Purchase_return_Edit from "../../Container/purchase_returneditContainer";

function Purchase_page({ current_user }) {
  const tabs = [
    "purchase",
    "purchase_history",
    "purchasereturn",
    "purchasereturn_history",
  ];
  var current_tab = localStorage.getItem("activeTab");
  if (!tabs.includes(current_tab)) {
    current_tab = "purchase";
  }

  const [activeTab, setActiveTab] = useState(current_tab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <Tabs
        defaultActiveKey={activeTab}
        transition={true}
        onSelect={handleTabSelect}
        id="noanim-tab-example"
        className="mb-3"
      >
        {current_user?.permissions?.includes("view_purchase") && (
          <Tab eventKey="purchase" title="Purchase">
            {activeTab === "purchase" && <Purchase />}
          </Tab>
        )}
        {current_user?.permissions?.includes("view_purchase_history") && (
          <Tab eventKey="purchase_history" title="Purchase History">
            {activeTab === "purchase_history" ? (
              <Purchase_history setActiveTab={setActiveTab} />
            ) : (
              activeTab === "purchase_Edit" && (
                <Purchase_Edit setActiveTab={setActiveTab} />
              )
            )}
          </Tab>
        )}

        {current_user?.permissions?.includes("view_purchase_return") && (
          <Tab eventKey="purchasereturn" title="Purchase Return">
            {activeTab === "purchasereturn" && <Purchase_return />}
          </Tab>
        )}
        {current_user?.permissions?.includes(
          "view_purchase_return_history"
        ) && (
          <Tab eventKey="purchasereturn_history" title="Return History">
            {activeTab === "purchasereturn_history" ? (
              <Purchase_return_history setActiveTab={setActiveTab} />
            ) : (
              activeTab === "purchasereturn_Edit" && (
                <Purchase_return_Edit setActiveTab={setActiveTab} />
              )
            )}
          </Tab>
        )}
      </Tabs>

      <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Purchase_page;
