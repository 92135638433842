import React, { useEffect, useState, useRef } from "react";
import "../sale/sale.css";
import Button from "react-bootstrap/Button";

import SearchIcon from "@material-ui/icons/Search";
import Spinner from "react-bootstrap/Spinner";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddIcon from "@material-ui/icons/Add";
import { ToastContainer } from "react-toastify";
import went_wrong_toast from "../alerts/went_wrong_toast";
import success_toast from "../alerts/success_toast";
import Red_toast from "../alerts/red_toast";
import Form from "../sale/saleform";
import Salewidget from "../sale/salewidget";
import Listwidget from "../sale/listwidget";
import { useTranslation } from "react-i18next";
import Paymentmodel from "../sale/payment_model";

function Sale_Return(props) {
  const user = props.state.setuser.user;
  const route = props.state.setuser.route;
  const selected_branch = props.state.Setcurrentinfo.selected_branch;
  const invoice_type = props.state.Setcurrentinfo.invoice_type;
  const current_user = props.state.Setcurrentinfo.current_user;
  const dispatch = props.Settable_history;
  const table_data = props.state.Setproducthistory.product_history;
  const settable_data = props.Setproduct_history;
  const settings = props.state.Setcurrentinfo.settings;
  const [all_product_option, setall_product_option] = useState([]);
  const [all_product, setall_product] = useState([]);
  const [all_suppliers, setall_suppliers] = useState([]);
  const [allpaymentmethod, setallpaymentmethod] = useState([
    { value: "cash", label: "Cash" },
    { value: "credit", label: "Credit" },
  ]);
  const [allordertype, setallordertype] = useState([
    { value: "Take Away", label: "Take Away" },
    { value: "Dine In", label: "Dine In" },
    { value: "Delivery", label: "Delivery" },
  ]);

  const [ordertype, setordertype] = useState({
    value: "Take Away",
    label: "Take Away",
  });

  const [alltable, setalltable] = useState(
    Array.from({ length: 20 }, (_, index) => ({
      value: `Table ${index + 1}`,
      label: `Table ${index + 1}`,
    }))
  );

  const [tableno, settableno] = useState("");
  const [search, setsearch] = useState("");

  var curr = new Date();
  var curdate = curr.toISOString().substring(0, 10);
  const [invoice, setinvoice] = useState("");
  const [date, setdate] = useState(curdate);
  const [counter, setcounter] = useState(1);
  const [supplier, setsupplier] = useState("");
  const [payment_type, setpayment_type] = useState({
    value: "cash",
    label: "Cash",
  });
  const [subtotal, setsubtotal] = useState(0);
  const [tax, settax] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [discount_percentage, setdiscount_percentage] = useState(0);
  const [discount_amount, setdiscount_amount] = useState(0);
  const [discount_type, setdiscount_type] = useState("cash");
  const [total, settotal] = useState(0);
  const [tax_perc, settax_perc] = useState(selected_branch?.tax_percentage);
  const [notes, setnotes] = useState("");

  const [showmodel, setshowmodel] = useState(false);
  const [categories, setcategories] = useState([]);
  const [category, setcategory] = useState({ id: "all", name: "All" });
  const [isloading, setisloading] = useState(false);

  const [suppliers_data, setsuppliers_data] = useState([]);
  const [showpaymentmodel, setshowpaymentmodel] = useState(false);

  const [getagain_invoice, setgetagain_invoice] = useState(true);
  const [saleman, setsaleman] = useState("");
  const [allsalemans, setallsalemans] = useState([]);
  const [editedRow, setEditedRow] = useState(null);

  useEffect(() => {
    const fetchinvoice = async () => {
      const response = await fetch(
        `${route}/api/sales/${selected_branch.id}/latest-invoice/`,
        {
          headers: { Authorization: `Bearer ${user.access}` },
        }
      );
      const json = await response.json();

      if (response.ok) {
        setinvoice(json.invoice_number);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    if (user) {
      fetchinvoice();
    }
  }, [selected_branch, getagain_invoice]);

  useEffect(() => {
    dispatch({ type: "Set_menuitem", data: "sale" });
    settable_data({ type: "Set_product_history", data: [] });
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      setall_product([]);
      if (current_user.profile.user_type === "user") {
        var url = `${route}/api/products/?user_id=${current_user.profile.parent_user}`;
      } else {
        var url = `${route}/api/products/?user_id=${current_user.id}`;
      }
      // }
      if (category.id !== "all") {
        url = `${url}&category_id=${category.id}`;
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setall_product(json);
        setall_product_option(json);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    if (user) {
      fetchProducts();
    }
  }, [selected_branch, category]);

  useEffect(() => {
    const fetchSuppliers = async () => {
      var url = `${route}/api/parties/?account_head=${selected_branch.id}&type=Customer`;
      if (!settings?.user_base?.account_base) {
        if (current_user.profile.user_type === "user") {
          url = `${route}/api/parties/?user_id=${current_user.profile.parent_user}&type=Customer`;
        } else {
          url = `${route}/api/parties/?user_id=${current_user.id}&type=Customer`;
        }
      }
      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setsuppliers_data(json);
        const supp = json.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setall_suppliers(supp);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };
    const fetchsalesman = async () => {
      if (current_user.profile.user_type === "user") {
        var url = `${route}/api/sales-persons/?user_id=${current_user.profile.parent_user}&account_head=${selected_branch.id}`;
      } else {
        var url = `${route}/api/sales-persons/?user_id=${current_user.id}&account_head=${selected_branch.id}`;
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setisloading(false);
        const optimize = json.map((item) => {
          return { value: item.id, label: item.name };
        });
        setallsalemans(optimize);
      }
      if (!response.ok) {
        went_wrong_toast();
        setisloading(false);
      }
    };

    const fetchWorkouts = async () => {
      if (current_user.profile.user_type === "user") {
        var url = `${route}/api/categories/?user_id=${current_user.profile.parent_user}`;
      } else {
        url = `${route}/api/categories/?user_id=${current_user.id}`;
      }

      const response = await fetch(`${url}`, {
        headers: { Authorization: `Bearer ${user.access}` },
      });
      const json = await response.json();

      if (response.ok) {
        setcategories([{ id: "all", name: "All" }, ...json]);
      }
      if (!response.ok) {
        went_wrong_toast();
      }
    };

    if (user) {
      fetchSuppliers();
      fetchsalesman();
      fetchWorkouts();
    }
  }, [selected_branch]);

  useEffect(() => {
    var total_total = 0;

    var total_tax = 0;
    table_data.forEach((item) => {
      total_tax += Number(item.tax_amount);
      total_total += Number(item.sub_total);
    });

    setsubtotal(total_total);
    settax(total_tax);
    settotal(total_total + Number(total_tax) - Number(discount));
  }, [table_data]);

  useEffect(() => {
    if (discount_type === "cash") {
      setdiscount(discount_amount);
      settotal(Number(subtotal) - Number(discount_amount) + Number(tax));
    } else {
      setdiscount_percentage(discount_amount);
      const amount = Number((Number(subtotal) / 100) * discount_amount);
      setdiscount(amount);
      settotal(Number(subtotal) - amount + Number(tax));
    }
  }, [discount_amount, discount_type]);

  const handleSubmit = async (selcted_customer) => {
    if (selected_branch && table_data) {
      const data_details = table_data.map((item) => {
        delete item["prod_id"];
        delete item["name"];
        delete item["code"];
        delete item["show"];

        return item;
      });
      if (payment_type.value == "cash") {
        var credit = total;
      } else {
        credit = 0;
      }

      setisloading(true);

      const response = await fetch(`${route}/api/sale-return/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify({
          reference: "SR",
          user: current_user.id,
          account_head: selected_branch.id,
          customer: selcted_customer.value,
          remarks: notes,
          payment_type: payment_type.value,
          table: tableno?.value,
          date: date,
          invoice: invoice,
          sub_total: subtotal,
          tax_amount: tax,
          discount_amount: discount,
          discount_percentage: discount_percentage,
          total: total,
          credit: credit,
          debit: total,
          order_type: ordertype.value,
          details: data_details,
          sale_person: saleman.value,
        }),
      });

      if (!response.ok) {
        setisloading(false);
        went_wrong_toast();
      }

      if (response.ok) {
        setisloading(false);

        success_toast();
        settable_data({ type: "Set_product_history", data: [] });
        setinvoice("");
        setsupplier("");
        setsubtotal(0);
        settax_perc(0);
        setdiscount_amount(0);
        settax(0);
        setdiscount(0);
        settotal(0);

        setnotes("");
        setgetagain_invoice(!getagain_invoice);
      }
    }
  };

  const handleNewSale = (e) => {
    e.preventDefault();
    settable_data({ type: "Set_product_history", data: [] });

    setsubtotal(0);
    settax_perc(0);
    settax(0);
    setdiscount(0);
    settotal(0);
    setnotes("");
    setdiscount_amount(0);
    setdiscount_percentage(0);
  };

  const handlePrint = async (selcted_customer) => {
    if (selected_branch) {
      const data_details = table_data.map((item) => {
        delete item["prod_id"];
        delete item["name"];
        delete item["code"];
        delete item["show"];

        return item;
      });

      if (payment_type.value == "cash") {
        var credit = total;
      } else {
        credit = 0;
      }
      const response = await fetch(`${route}/api/sale-return/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.access}`,
        },
        body: JSON.stringify({
          reference: "SR",
          user: current_user.id,
          account_head: selected_branch.id,
          customer: selcted_customer.value,
          remarks: notes,
          table: tableno?.value,
          payment_type: payment_type.value,
          date: date,
          invoice: invoice,
          sub_total: subtotal,
          tax_amount: tax,
          discount_amount: discount,
          discount_percentage: discount_percentage,
          total: total,
          credit: credit,
          debit: total,
          order_type: ordertype.value,
          details: data_details,
          sale_person: saleman.value,
        }),
      });
      const json = await response.json();

      if (!response.ok) {
        went_wrong_toast();
      }

      if (response.ok) {
        localStorage.setItem("data", JSON.stringify(json));
        settable_data({ type: "Set_product_history", data: [] });
        setinvoice("");

        setsupplier("");

        setsubtotal(0);
        settax_perc(0);
        settax(0);
        setdiscount(0);
        setgetagain_invoice(!getagain_invoice);
        settotal(0);
        setdiscount_amount(0);

        setnotes("");
        if (invoice_type.code === "A4") {
          window.open("/invoice/sales_return", "_blank");
        } else if (invoice_type.code === "80mm") {
          if (settings?.account_base?.invoice_version === "Version 02 80mm") {
            window.open("/invoice_80_2/sales_return", "_blank");
          } else if (
            settings?.account_base?.invoice_version === "Version 01 80mm"
          ) {
            window.open("/invoice_80/sales_return", "_blank");
          }
        }
      }
    }
  };

  const handleproduct_selection = (selected_option) => {
    const item_present = table_data.filter((item) => {
      return item.product === selected_option.value;
    });

    ////////////////////////////////////////////////////
    var filter_data = all_product.filter((item) => {
      if (item.id === selected_option.value) {
        return item;
      }
    });

    if (item_present.length === 0) {
      add_product_totable(item_present, filter_data);
    } else {
      add_quantity(item_present);
    }
  };

  const add_product_totable = (item_present, filter_data) => {
    ///////////////////////////////////////////////////////////////////////
    filter_data = filter_data.map((item) => {
      var generate_row = {
        product: item.id,
        name: item.name,
        code: item.code,
        quantity: 1,
        show: true,

        price: item.sale_rate,
        sub_total: item.sale_rate,

        tax_percentage: 0,
        tax_amount: 0,

        total: item.sale_rate,
      };
      if (settings?.account_base?.item_wise_tax) {
        const calculate_total_pr_item =
          generate_row.total +
          (item.sale_rate / 100) * selected_branch.tax_percentage;

        generate_row = {
          ...generate_row,

          tax_percentage: selected_branch.tax_percentage,
          tax_amount: (item.sale_rate / 100) * selected_branch.tax_percentage,
          total: calculate_total_pr_item,
        };
      }

      return generate_row;
    });

    if (filter_data[0]) {
      settable_data({ type: "Create_product_history", data: filter_data[0] });
    }
  };

  const add_quantity = (item_present) => {
    const item = item_present[0];

    item.quantity = Number(item.quantity) + 1;

    item.sub_total = item.quantity * item.price;

    item.tax_amount = (item.sub_total / 100) * item.tax_percentage;
    item.total = item.quantity * item.price + item.tax_amount;

    settable_data({ type: "Update_product_history", data: item });
  };

  const handlecellchange = (newValue, row, column) => {
    if (column.dataField === "quantity") {
      const new_data = table_data.filter((item, index) => {
        return item.product === row.product;
      });
      const item = new_data[0];

      const calculate_total =
        item.price * newValue +
        ((item.price * newValue) / 100) * item.tax_percentage;

      const createnew_data = {
        product: item.product,

        name: item.name,
        code: item.code,
        quantity: newValue,
        show: item.show,
        price: item.price,
        sub_total: item.price * newValue,

        tax_percentage: item.tax_percentage,
        tax_amount: ((item.price * newValue) / 100) * item.tax_percentage,
        total: calculate_total,
      };
      settable_data({
        type: "Update_product_history",
        data: createnew_data,
      });
    } else if (column.dataField === "price") {
      const new_data = table_data.filter((item) => {
        return item.product === row.product;
      });
      const item = new_data[0];
      const calculate_total =
        newValue * item.quantity +
        ((newValue * item.quantity) / 100) * item.tax_percentage;

      const createnew_data = {
        product: item.product,

        name: item.name,
        code: item.code,
        quantity: item.quantity,
        show: item.show,
        price: newValue,
        sub_total: newValue * item.quantity,

        tax_percentage: item.tax_percentage,
        tax_amount: ((newValue * item.quantity) / 100) * item.tax_percentage,

        total: calculate_total,
      };
      settable_data({ type: "Update_product_history", data: createnew_data });
    } else if (column.dataField === "tax_percentage") {
      const new_data = table_data.filter((item) => {
        return item.product === row.product;
      });
      const item = new_data[0];
      const createnew_data = {
        product: item.product,

        name: item.name,
        code: item.code,
        quantity: item.quantity,
        show: item.show,
        price: item.price,
        sub_total: item.sub_total,

        tax_percentage: newValue,
        tax_amount: (item.sub_total / 100) * newValue,

        total: item.sub_total + (item.sub_total / 100) * newValue,
      };

      settable_data({ type: "Update_product_history", data: createnew_data });
    } else if (column.dataField === "show") {
      const new_data = table_data.filter((item) => {
        return item.product === row.product;
      });
      const item = new_data[0];
      const createnew_data = {
        product: item.product,

        name: item.name,
        code: item.code,
        quantity: item.quantity,
        show: newValue,
        price: item.price,
        sub_total: item.sub_total,

        tax_percentage: item.tax_percentage,
        tax_amount: item.tax_amount,

        total: item.total,
      };

      settable_data({ type: "Update_product_history", data: createnew_data });
    }
  };

  const deleterow = (row) => {
    settable_data({ type: "Delete_product_history", data: row });
  };

  const handlesearch = (e) => {
    const value = e.target.value;
    setsearch(value);
    if (value) {
      const optimize = all_product_option.filter((item) => {
        return (
          item.name.toLowerCase().includes(value) ||
          item.name.toUpperCase().includes(value)
        );
      });
      setall_product(optimize);
    } else {
      setall_product(all_product_option);
    }
  };
  const closeallitem = () => {
    const optimize = table_data.map((item) => {
      item["show"] = false;
      return item;
    });
    settable_data({ type: "Set_product_history", data: optimize });
  };

  return (
    <div>
      <div>
        <div>
          <div className="d-flex ">
            <div className="col-sm-4 ">
              <div className="card p-2 me-2" style={{ height: " 80vh " }}>
                {table_data.map((item, index) => {
                  return (
                    <Listwidget
                      item={item}
                      funct={(e, item) => deleterow(item)}
                      functchange={(e, item, column) =>
                        handlecellchange(e, item, column)
                      }
                      closeallitem={closeallitem}
                    />
                  );
                })}
              </div>

              <div
                className="p-2 mt-2 me-2 d-flex align-items-center justify-content-between add"
                style={{
                  backgroundColor: "rgba(255, 165, 0,0.3)",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setshowmodel(true);
                }}
              >
                <strong>Add</strong>
                <div
                  className="d-flex align-items-center"
                  style={{ color: "rgba(255, 100, 0,1)" }}
                >
                  <p className="m-0 me-3 ">Discount</p>
                </div>
              </div>

              <div className="p-2 mt-2 me-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h4>SubTotal</h4>
                  <h4>{subtotal}</h4>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>Tax</h4>
                  <h4>{tax}</h4>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h4>Total</h4>
                  <h4>{total}</h4>
                </div>
              </div>

              <div className="p-2 mt-2 me-2 d-flex justify-content-end align-items-center">
                <div>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={handleNewSale}
                    className="me-2"
                  >
                    <AddIcon /> New Sale
                  </Button>

                  <Button
                    type="button"
                    variant="success"
                    onClick={(e) => setshowpaymentmodel(true)}
                    disabled={!table_data.length > 0}
                  >
                    <ArrowForwardIcon /> Proceed
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-8  m-0 ">
              <div className="d-flex justify-content-center ">
                <div className="card border-0 col-6 p-3 mb-2 ">
                  <div className="d-flex justify-content-between align-items-center p-2  searchdiv">
                    <input
                      className=" search"
                      placeholder="Search Products..."
                      value={search}
                      onChange={handlesearch}
                    />

                    <div>
                      <SearchIcon />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2 overflow">
                <div className="d-flex">
                  {categories.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          category.id === item.id
                            ? "p-2 ps-3 pe-3 me-1 category categoryselected"
                            : "p-2 ps-3 pe-3 me-1 category"
                        }
                        style={{ opacity: 0.7 }}
                        onClick={() => {
                          setcategory(item);
                        }}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="row m-0 p-0 d-flex" style={{ margin: "auto" }}>
                {all_product.map((item) => {
                  return (
                    <Salewidget
                      key={item.id}
                      item={item}
                      funct={(e, item) => {
                        closeallitem();
                        handleproduct_selection(item);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <ToastContainer autoClose={1000} hideProgressBar={true} theme="dark" />
      </div>

      {showmodel && (
        <Form
          show={showmodel}
          onHide={() => setshowmodel(false)}
          discount={discount_amount}
          setdiscount={setdiscount_amount}
          discount_type={discount_type}
          setdiscount_type={setdiscount_type}
        />
      )}

      {showpaymentmodel && (
        <Paymentmodel
          show={showpaymentmodel}
          onHide={() => setshowpaymentmodel(false)}
          supplier={supplier}
          setsupplier={setsupplier}
          all_suppliers={all_suppliers}
          setall_suppliers={setall_suppliers}
          payment_type={payment_type}
          setpayment_type={setpayment_type}
          allpaymentmethod={allpaymentmethod}
          saleman={saleman}
          setsaleman={setsaleman}
          allsalemans={allsalemans}
          ordertype={ordertype}
          setordertype={setordertype}
          allordertype={allordertype}
          handleSubmit={handleSubmit}
          handlePrint={handlePrint}
          notes={notes}
          setnotes={setnotes}
          alltable={alltable}
          tableno={tableno}
          settableno={settableno}
          current_user={current_user}
          user={user}
          route={route}
          selected_branch={selected_branch}
        />
      )}
    </div>
  );
}
export default Sale_Return;
