import { connect } from "react-redux";
import Assign_rawmaterial from "../Components/rate/rate";
import { Settablehistory } from "../Service/Actions/action";

const dispatchpart = (dispatch) => ({
  Settable_history: (data) => dispatch(Settablehistory(data)),
});

const getpart = (state) => ({
  state,
});

export default connect(getpart, dispatchpart)(Assign_rawmaterial);
