import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Categories from "../../Container/categoryContainer";
import Subcategories from "../../Container/subcategpryContainer";
import Product from "../../Container/productContainer";
import Rate from "../../Container/rateContainer";
import { ToastContainer } from "react-toastify";
import Stock from "../../Container/stockContainer";

function Product_page({ current_user }) {
  const tabs = ["stock", "rate", "products", "category", "company"];
  var current_tab = localStorage.getItem("activeTab");
  if (!tabs.includes(current_tab)) {
    current_tab = "stock";
  }

  const [activeTab, setActiveTab] = useState(current_tab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <Tabs
        defaultActiveKey={activeTab}
        transition={true}
        onSelect={handleTabSelect}
        id="noanim-tab-example"
        className="mb-3"
      >
        {current_user?.permissions?.includes("view_product") && (
          <Tab eventKey="products" title="Products">
            {activeTab === "products" && <Product />}
          </Tab>
        )}
        {current_user?.permissions?.includes("view_stock") && (
          <Tab eventKey="stock" title="Raw Material">
            {activeTab === "stock" && <Stock />}
          </Tab>
        )}
        {current_user?.permissions?.includes("view_stock") && (
          <Tab eventKey="rate" title="Product Material">
            {activeTab === "rate" && <Rate />}
          </Tab>
        )}

        {current_user?.permissions?.includes("view_product") && (
          <Tab eventKey="category" title="Category">
            {activeTab === "category" && <Categories />}
          </Tab>
        )}
        {current_user?.permissions?.includes("view_product") && (
          <Tab eventKey="company" title="Sub Category">
            {activeTab === "company" && <Subcategories />}
          </Tab>
        )}
      </Tabs>
      <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default Product_page;
