import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./sale.css";
import SaveIcon from "@material-ui/icons/Save";
import Button from "react-bootstrap/Button";
import Select from "../alerts/select";
import PrintIcon from "@material-ui/icons/Print";
import TextField from "@mui/material/TextField";
import Red_toast from "../alerts/red_toast";
import { IconButton } from "@material-ui/core";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@material-ui/icons/Add";
import Select1 from "react-select";
function Paymentmodel({
  show,
  onHide,
  supplier,
  setsupplier,
  all_suppliers,
  setall_suppliers,
  payment_type,
  setpayment_type,
  allpaymentmethod,
  saleman,
  setsaleman,
  allsalemans,
  ordertype,
  setordertype,
  allordertype,
  handleSubmit,
  handlePrint,
  notes,
  setnotes,
  alltable,
  tableno,
  settableno,
  current_user,
  user,
  route,
  selected_branch,
}) {
  const [addcustomer, setaddcustomer] = useState(false);
  const [name, setname] = useState("");
  const [contact, setcontact] = useState("");

  const addcustomerfun = async (text) => {
    const formData = new FormData();
    formData.append("name", name);
    if (current_user.profile.user_type === "user") {
      formData.append("user", current_user.profile.parent_user);
    } else {
      formData.append("user", current_user.id);
    }
    formData.append("cnic", "");
    formData.append("contact", contact);
    formData.append("ntn", "");
    formData.append("strn", "");
    formData.append("address", "");
    formData.append("percentage", "");
    formData.append("type", "customer");
    formData.append("account_head", selected_branch.id);

    const response = await fetch(`${route}/api/parties/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.access}`,
      },
      body: formData,
    });
    const json = await response.json();

    if (!response.ok) {
      Red_toast("Error in saving Customer !");
    }

    if (response.ok) {
      setname("");
      setcontact("");
      setall_suppliers([
        ...all_suppliers,
        { value: json.id, label: json.name },
      ]);

      setaddcustomer(false);
      if (text === "save") {
        handleSubmit({ value: json.id, label: json.name });
      } else {
        handlePrint({ value: json.id, label: json.name });
      }

      onHide();
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    if (!addcustomer) {
      if (supplier) {
        handleSubmit(supplier);
        onHide();
      } else {
        Red_toast("Select Customer!");
      }
    } else addcustomerfun("save");
  };

  const handleprint = (e) => {
    e.preventDefault();
    if (!addcustomer) {
      if (supplier) {
        handlePrint(supplier);
        onHide();
      } else {
        Red_toast("Select Customer!");
      }
    } else addcustomerfun("print");
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zoom: ".8" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-md-center"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handlesubmit}>
          <div className="d-md-flex align-items-md-start">
            <div className="col-6  pe-sm-2">
              <InputGroup>
                <Select1
                  className={
                    supplier !== ""
                      ? "form-control selector customer"
                      : "form-control selector"
                  }
                  styles={selectStyles}
                  options={all_suppliers}
                  placeholder={"Customer"}
                  value={supplier}
                  onChange={(e) => {
                    setsupplier(e);
                  }}
                ></Select1>

                <IconButton
                  className="p-0 ps-1 pe-1"
                  style={{
                    backgroundColor: "#0d6efd",
                    borderRadius: "0",
                  }}
                  onClick={() => {
                    setaddcustomer(!addcustomer);
                  }}
                >
                  <AddIcon
                    style={{ color: "white", height: "fit-content" }}
                    fontSize="medium"
                  />
                </IconButton>
              </InputGroup>
            </div>

            <div className="col-sm-6 ps-sm-2">
              <Select
                value={saleman}
                funct={setsaleman}
                options={allsalemans}
                placeholder={"Sale Person"}
              />
            </div>
          </div>
          {addcustomer && (
            <div className="d-md-flex align-items-md-center ">
              <div className="col-sm-6 pe-sm-2">
                <TextField
                  className="form-control mb-3"
                  id="outlined-basic"
                  label="Name"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  size="small"
                  required={true}
                />
              </div>
              <div className="col-sm-6 ps-sm-2">
                <TextField
                  className="form-control mb-3"
                  id="outlined-basic"
                  label="Contact"
                  value={contact}
                  onChange={(e) => {
                    setcontact(e.target.value);
                  }}
                  size="small"
                  required={true}
                />
              </div>
            </div>
          )}

          <div className="d-md-flex align-items-md-center">
            <div className="col-sm-6 pe-sm-2">
              <Select
                value={payment_type}
                funct={setpayment_type}
                options={allpaymentmethod}
                placeholder={"Payment Type"}
                required={true}
              />
            </div>
            <div className="col-sm-6 ps-sm-2">
              <Select
                value={ordertype}
                funct={setordertype}
                options={allordertype}
                placeholder={"Order Type"}
                required={true}
              />
            </div>
          </div>

          <div className="d-md-flex align-items-md-center">
            {ordertype.value === "Dine In" && (
              <div className="col-sm-6 pe-sm-2">
                <Select
                  value={tableno}
                  funct={settableno}
                  options={alltable}
                  placeholder={"Table No"}
                  required={true}
                />
              </div>
            )}
            <div
              className={
                ordertype.value === "Dine In"
                  ? "col-sm-6 mb-3 ps-sm-2"
                  : "col-sm-6 mb-3 pe-sm-2"
              }
            >
              <TextField
                multiline
                className="form-control"
                id="outlined-basic"
                label="Notes"
                value={notes}
                onChange={(e) => {
                  setnotes(e.target.value);
                }}
                size="small"
              />
            </div>
          </div>
          <hr className="mb-1"></hr>
          <div className=" d-flex flex-row-reverse mt-2 me-2">
            <Button type="submit" variant="outline-primary">
              <SaveIcon /> Save
            </Button>

            <Button
              onClick={handleprint}
              className="me-3"
              variant="outline-success"
            >
              <PrintIcon /> Print
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Paymentmodel;
