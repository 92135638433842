import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import User from "../../Container/userContainer";
import Branch_manage from "../../Container/branchmanagContainer";
import Assign_role from "../../Container/assign_roleContainer";
import Account_head from "../../Container/account_headContiner";
import Accounts from "../../Container/accountContainer";
import Settings from "../../Container/settingContainer";
import { ToastContainer } from "react-toastify";

function User_page() {
  const tabs = [
    "User",
    "Role",
    "Group",
    "Assign Account",
    "Account",
    "settings",
  ];
  var current_tab = localStorage.getItem("activeTab");
  if (!tabs.includes(current_tab)) {
    current_tab = "User";
  }

  const [activeTab, setActiveTab] = useState(current_tab);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <Tabs
        defaultActiveKey={activeTab}
        transition={true}
        onSelect={handleTabSelect}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab eventKey="User" title="Users">
          {activeTab === "User" && <User />}
        </Tab>

        <Tab eventKey="Role" title="Assign Roles">
          {activeTab === "Role" && <Assign_role />}
        </Tab>

        <Tab eventKey="Group" title="Roles & Permissions">
          {activeTab === "Group" && <Branch_manage />}
        </Tab>

        <Tab eventKey="Account" title=" Account Heads">
          {activeTab === "Account" && <Accounts />}
        </Tab>

        <Tab eventKey="Assign Account" title="Assign Accounts">
          {activeTab === "Assign Account" && <Account_head />}
        </Tab>

        <Tab eventKey="settings" title="Settings">
          {activeTab === "settings" && <Settings />}
        </Tab>
      </Tabs>
      <ToastContainer autoClose={2000} hideProgressBar={true} theme="dark" />
    </div>
  );
}

export default User_page;
