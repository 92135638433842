import React, { useState, useRef } from "react";
import "./home.css";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Outlet } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { Link } from "react-router-dom";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Header from "../Container/headerContainer";
import Footer from "../Components/footer";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import TurnedInIcon from "@material-ui/icons/TurnedIn";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { useTranslation } from "react-i18next";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ArchiveIcon from "@material-ui/icons/Archive";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";

function Layout(props) {
  const { t } = useTranslation();
  const user = props.state.setuser.user;
  const menu_status = props.state.Settablehistory.menu_status;

  const current_user = props.state.Setcurrentinfo.current_user;

  const { collapseSidebar, toggleSidebar, broken, collapsed } = useProSidebar();
  const [checkcollapse, setcheckcollapse] = useState(true);

  const handlemouseleave = () => {
    if (checkcollapse && !collapsed) {
      collapseSidebar();
    }
  };

  const handlemouseenter = () => {
    if (checkcollapse && collapsed) {
      collapseSidebar();
    }
  };
  return (
    <div id="app" className="d-flex" style={{ zoom: ".7" }}>
      {user && (
        <Sidebar
          breakPoint="md"
          defaultCollapsed={true}
          rootStyles={{ color: "whitesmoke" }}
          backgroundColor="#000"
        >
          <div
            style={{
              textAlign: "center",
              borderBottom: "3px solid #757575",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar className="mt-1 mb-1 ms-3 me-4" />
            <h5>{current_user && current_user.username}</h5>
          </div>
          <Menu
            onMouseEnter={handlemouseenter}
            onMouseLeave={handlemouseleave}
            style={{ height: "135vh", overflow: "auto" }}
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0 || level === 1) {
                  return {
                    color: active ? "skyblue" : undefined,
                    "&:hover": {
                      backgroundColor: "#335B8C !important",
                      color: "white !important",
                      borderRadius: "8px !important",
                      fontWeight: "bold !important",
                    },
                  };
                }
              },
            }}
          >
            {current_user?.permissions?.includes("view_dashboard") && (
              <MenuItem
                active={menu_status === "dashboard"}
                icon={<DashboardIcon />}
                component={<Link to="/dashboard" />}
              >
                {t("side_bar_dashboard")}
              </MenuItem>
            )}

            {(current_user?.profile?.user_type === "admin" ||
              current_user?.profile?.user_type === "super_admin") && (
              <MenuItem
                active={menu_status === "user"}
                icon={<PersonIcon />}
                component={<Link to="/user" />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              >
                User Management
              </MenuItem>
            )}

            {current_user?.permissions?.includes("view_parties") && (
              <MenuItem
                active={menu_status === "customer"}
                icon={<SupervisorAccountIcon />}
                component={<Link to={`/parties`} />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              >
                Parties
              </MenuItem>
            )}

            {current_user?.permissions?.includes("view_product_page") && (
              <MenuItem
                active={menu_status === "products"}
                icon={<TurnedInIcon />}
                component={<Link to="/products" />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              >
                Products
              </MenuItem>
            )}

            {current_user?.permissions?.includes("view_payments") && (
              <MenuItem
                active={menu_status === "payment_customer"}
                icon={<AccountBalanceWalletIcon />}
                component={<Link to="/payments" />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              >
                Payments
              </MenuItem>
            )}

            {current_user?.permissions?.includes("view_journals") && (
              <MenuItem
                active={menu_status === "journal_customer"}
                icon={<ArchiveIcon />}
                component={<Link to="/journal_page" />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              >
                Journals
              </MenuItem>
            )}

            {current_user?.permissions?.includes("view_purchase_page") && (
              <MenuItem
                active={menu_status === "purchase"}
                icon={<ShoppingBasketIcon />}
                component={<Link to="/purchase_page" />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              >
                Purchase
              </MenuItem>
            )}

            {current_user?.permissions?.includes("view_sale_page") && (
              <MenuItem
                active={menu_status === "sale"}
                icon={<LocalAtmIcon />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
                component={<Link to="/sale_page" />}
              >
                Sale
              </MenuItem>
            )}

            <MenuItem
              active={menu_status === "materialbook"}
              icon={<LocalLibraryIcon />}
              rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
              component={<Link to="/material_book" />}
            >
              Material Book
            </MenuItem>

            {current_user?.permissions?.includes("view_expenses") && (
              <MenuItem
                active={menu_status === "expenses"}
                icon={<MoneyOffIcon />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
                component={<Link to="/expenses" />}
              >
                Expenses
              </MenuItem>
            )}
            {current_user?.permissions?.includes("view_report_page") && (
              <MenuItem
                active={menu_status === "sale_report"}
                icon={<ReceiptIcon />}
                rootStyles={{ color: "whitesmoke", backgroundColor: "#000" }}
                component={<Link to="/reports" />}
              >
                Reports
              </MenuItem>
            )}
          </Menu>
        </Sidebar>
      )}

      <div className="w-100 ">
        <div className="header ">
          {user && (
            <Header
              togglefun={toggleSidebar}
              collapsefun={collapseSidebar}
              broken={broken}
              statefun={() => setcheckcollapse(!checkcollapse)}
            />
          )}
        </div>

        {user ? (
          <div
            style={{
              minHeight: "126vh",
              backgroundColor: "rgb(241, 245, 245)",
            }}
          >
            <Outlet />
          </div>
        ) : (
          <div
            style={{
              minHeight: "125vh",
              backgroundColor: "rgb(241, 245, 245)",
            }}
          >
            <Outlet />
          </div>
        )}

        <div className="footer">{user && <Footer />}</div>
      </div>
    </div>
  );
}

export default Layout;
